import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  orders: {
    total: 0,
    items: [],
    options: {},
  },
  orderNotes: [],
  orderItemImages: [],
  jobOrderItems: [],

})

export const state = initState

export const getters = {
}

export const mutations = {

  setOrderNotes(state, {notes}) {
    state.orderNotes = notes
  },

  setOrderItemImages(state, {images}) {
    state.orderItemImages = images
  },

  setJobImages(state, {jobImages}) {
    state.jobImages = jobImages
  },
}

export const actions = {

  async fetchOrderNotes({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/JobOrders/${jobID}/notes`)
    if (result) {
      commit('setOrderNotes', {notes: result})
    }
    return result
  },

  async saveOrderNote({commit, state}, {note, image, jobID}) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('note', note)
    formData.append('jobOrderID', jobID)
    return await this.$axios.$post(`/JobOrders/notes`, formData)

  },


  async fetchOrderItemImages({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/webpos/order/images/${jobID}`)
    if (result && !result.hasError) {
      commit('setOrderItemImages', {images: result.value})
      return result.value
    }
    else{
      this.$toast.error(result.errorMessages[0])
    }
  },

  removeImages({commit, state}){
    commit('setOrderItemImages', {images: []})
  },


  async fetchOrderDetails({commit, state}, {orderID}) {
    const res = await this.$axios.get(`webpos/order/detail/${orderID}`)
    if(res && res.data && !res.data.hasErrors){
      return res.data.value
    }
    else{
      this.$toast.error('Error occured while getting order detail');
      return [];
    }
  },

  async getInvoiceDetailForOrder({}, {orderID}) {
    try{
      const url = `/webpos/order/invoice-detail`;

      const result = await this.$axios.$get(`${url}/${orderID}`);

      if(result && !result.hasErrors){
        return result.value;
      }
      else{
        this.$toast.error('Error while getting invoice details.')
        return {};
      }
    }
    catch{
      this.$toast.error('Error while getting invoice detail.')
      return {};
    }
  }


}
