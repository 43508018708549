import {mapFilters} from '@/utils/helpers';
const initState = () => ({
})

export const state = initState
export const getters = {
}
export const mutations = {}

export const actions = {
  async fetchCustomerCredits({commit},{filters,pagingInfo}){
    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    let result = await this.$axios.post(`webpos/customer-credit`,filter);
    if (result.data.hasErrors){
      this.$toast.error("Error while getting customer credits");
    }
    else
    {
     return result.data;
    }
  },
  async getCustomerCreditItems({commit},{memoID}){
    let result = await this.$axios.get(`webpos/customer-credit/items-by-memo-id/${memoID}`);
    if (result.data.hasErrors){
      this.$toast.error("Error while getting customer credit items!");
    }
    else
    {
      return result.data.value;
    }
  },
  async getCustomerInvoicesForCustomerCredits({commit},{customerId}){
    let result = await this.$axios.get(`webpos/customer-credit/invoice-items/${customerId}`);
    if (result.data.hasErrors){
      this.$toast.error("Error while getting customer invoice items!");
    }
    else
    {
      return result.data.value;
    }
  },
  async fetchItemTypeByCode({commit}, {itemCode}){
    let result = await this.$axios.$get(`/ItemTypes/getbysku/${itemCode}?includeattributes=true&includesubitems=true`)
    if (result.id) {
      return result
    } else {
      this.$toast.error("Item type was not found!");
      return null
    }
  },
  async createNewCustomerCredit({commit},{customerCredit}){

    let result = await this.$axios.post(`webpos/customer-credit/create-customer-credit`
    , customerCredit);
    if (result.data.hasErrors){

      this.$toast.error("Error while creating customer credit!");
    }

    if (result.data.value.id > 0){
      this.$toast.success("Customer credit created successfully!");
    }

    return result.data
  }

}

