import {formActions} from "@/enums/formActions";
import {frontends} from "@/enums/frontends";


const initState = () => ({

})

export const state = initState;

export const actions ={
  async fetchModules() {
    try{
      const result =  await this.$axios.$get(`webpos/modules/getApplicationModules`);
      if (result == null) {this.$toast.error("Error while getting modules from database!");}
      return result;
    }
    catch{
      this.$toast.error("Error while getting modules from database!");
    }
  },

  async saveModule({commit}, {module}){
    try{
      await this.$axios.$post(`webpos/modules/saveApplicationModule`, module);
    }
    catch{
      this.$toast.error("Error while saving the new settings for the application module.");
    }
  }
}
