import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19a4176d = () => interopDefault(import('..\\pages\\catalog\\index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _10bffda3 = () => interopDefault(import('..\\pages\\customer-credits\\index.vue' /* webpackChunkName: "pages/customer-credits/index" */))
const _e665b6a4 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _5674c7c5 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _75958d44 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _e57260b4 = () => interopDefault(import('..\\pages\\my-profile\\index.vue' /* webpackChunkName: "pages/my-profile/index" */))
const _c646e94c = () => interopDefault(import('..\\pages\\new-sale\\index.vue' /* webpackChunkName: "pages/new-sale/index" */))
const _1265d129 = () => interopDefault(import('..\\pages\\orders\\index.vue' /* webpackChunkName: "pages/orders/index" */))
const _cc70b23e = () => interopDefault(import('..\\pages\\payments\\index.vue' /* webpackChunkName: "pages/payments/index" */))
const _48fe61ab = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _d6b47bf2 = () => interopDefault(import('..\\pages\\reports\\index.vue' /* webpackChunkName: "pages/reports/index" */))
const _37047149 = () => interopDefault(import('..\\pages\\wishlist\\index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _5f276967 = () => interopDefault(import('..\\pages\\cms\\components\\index.vue' /* webpackChunkName: "pages/cms/components/index" */))
const _8de440ba = () => interopDefault(import('..\\pages\\cms\\contents\\index.vue' /* webpackChunkName: "pages/cms/contents/index" */))
const _4916166f = () => interopDefault(import('..\\pages\\configuration\\admin-settings\\index.vue' /* webpackChunkName: "pages/configuration/admin-settings/index" */))
const _193b261e = () => interopDefault(import('..\\pages\\configuration\\integrations\\index.vue' /* webpackChunkName: "pages/configuration/integrations/index" */))
const _fef6d664 = () => interopDefault(import('..\\pages\\customer-credits\\create-new.vue' /* webpackChunkName: "pages/customer-credits/create-new" */))
const _5ddc8014 = () => interopDefault(import('..\\pages\\register\\transactions\\index.vue' /* webpackChunkName: "pages/register/transactions/index" */))
const _7840b5db = () => interopDefault(import('..\\pages\\reports\\customer-balances.vue' /* webpackChunkName: "pages/reports/customer-balances" */))
const _c717b786 = () => interopDefault(import('..\\pages\\reports\\customer-statements-dialog.vue' /* webpackChunkName: "pages/reports/customer-statements-dialog" */))
const _504d119d = () => interopDefault(import('..\\pages\\reports\\events-report.vue' /* webpackChunkName: "pages/reports/events-report" */))
const _184b6b56 = () => interopDefault(import('..\\pages\\reports\\layaways-report.vue' /* webpackChunkName: "pages/reports/layaways-report" */))
const _06c4e833 = () => interopDefault(import('..\\pages\\reports\\sales-reports.vue' /* webpackChunkName: "pages/reports/sales-reports" */))
const _70c79ac4 = () => interopDefault(import('..\\pages\\reports\\wishlist-items-report.vue' /* webpackChunkName: "pages/reports/wishlist-items-report" */))
const _5d390b4f = () => interopDefault(import('..\\pages\\services\\layaways\\index.vue' /* webpackChunkName: "pages/services/layaways/index" */))
const _9d24a462 = () => interopDefault(import('..\\pages\\services\\repair-center\\index.vue' /* webpackChunkName: "pages/services/repair-center/index" */))
const _66c213e7 = () => interopDefault(import('..\\pages\\services\\layaways\\create-new-layaway.vue' /* webpackChunkName: "pages/services/layaways/create-new-layaway" */))
const _04e5253e = () => interopDefault(import('..\\pages\\services\\repair-center\\create-new-repair-ticket.vue' /* webpackChunkName: "pages/services/repair-center/create-new-repair-ticket" */))
const _05d1f9b4 = () => interopDefault(import('..\\pages\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/cms/components/_componentId" */))
const _6fbb4c74 = () => interopDefault(import('..\\pages\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/cms/contents/_contentId" */))
const _3a7e3ea9 = () => interopDefault(import('..\\pages\\catalog\\_id\\products\\index.vue' /* webpackChunkName: "pages/catalog/_id/products/index" */))
const _0c29f6c7 = () => interopDefault(import('..\\pages\\product\\_id\\_slug\\index.vue' /* webpackChunkName: "pages/product/_id/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _19a4176d,
    name: "catalog___en"
  }, {
    path: "/customer-credits",
    component: _10bffda3,
    name: "customer-credits___en"
  }, {
    path: "/fr",
    component: _e665b6a4,
    name: "index___fr"
  }, {
    path: "/login",
    component: _5674c7c5,
    name: "login___en"
  }, {
    path: "/logout",
    component: _75958d44,
    name: "logout___en"
  }, {
    path: "/my-profile",
    component: _e57260b4,
    name: "my-profile___en"
  }, {
    path: "/new-sale",
    component: _c646e94c,
    name: "new-sale___en"
  }, {
    path: "/orders",
    component: _1265d129,
    name: "orders___en"
  }, {
    path: "/payments",
    component: _cc70b23e,
    name: "payments___en"
  }, {
    path: "/register",
    component: _48fe61ab,
    name: "register___en"
  }, {
    path: "/reports",
    component: _d6b47bf2,
    name: "reports___en"
  }, {
    path: "/wishlist",
    component: _37047149,
    name: "wishlist___en"
  }, {
    path: "/cms/components",
    component: _5f276967,
    name: "cms-components___en"
  }, {
    path: "/cms/contents",
    component: _8de440ba,
    name: "cms-contents___en"
  }, {
    path: "/configuration/admin-settings",
    component: _4916166f,
    name: "configuration-admin-settings___en"
  }, {
    path: "/configuration/integrations",
    component: _193b261e,
    name: "configuration-integrations___en"
  }, {
    path: "/customer-credits/create-new",
    component: _fef6d664,
    name: "customer-credits-create-new___en"
  }, {
    path: "/fr/catalog",
    component: _19a4176d,
    name: "catalog___fr"
  }, {
    path: "/fr/customer-credits",
    component: _10bffda3,
    name: "customer-credits___fr"
  }, {
    path: "/fr/login",
    component: _5674c7c5,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _75958d44,
    name: "logout___fr"
  }, {
    path: "/fr/my-profile",
    component: _e57260b4,
    name: "my-profile___fr"
  }, {
    path: "/fr/new-sale",
    component: _c646e94c,
    name: "new-sale___fr"
  }, {
    path: "/fr/orders",
    component: _1265d129,
    name: "orders___fr"
  }, {
    path: "/fr/payments",
    component: _cc70b23e,
    name: "payments___fr"
  }, {
    path: "/fr/register",
    component: _48fe61ab,
    name: "register___fr"
  }, {
    path: "/fr/reports",
    component: _d6b47bf2,
    name: "reports___fr"
  }, {
    path: "/fr/wishlist",
    component: _37047149,
    name: "wishlist___fr"
  }, {
    path: "/register/transactions",
    component: _5ddc8014,
    name: "register-transactions___en"
  }, {
    path: "/reports/customer-balances",
    component: _7840b5db,
    name: "reports-customer-balances___en"
  }, {
    path: "/reports/customer-statements-dialog",
    component: _c717b786,
    name: "reports-customer-statements-dialog___en"
  }, {
    path: "/reports/events-report",
    component: _504d119d,
    name: "reports-events-report___en"
  }, {
    path: "/reports/layaways-report",
    component: _184b6b56,
    name: "reports-layaways-report___en"
  }, {
    path: "/reports/sales-reports",
    component: _06c4e833,
    name: "reports-sales-reports___en"
  }, {
    path: "/reports/wishlist-items-report",
    component: _70c79ac4,
    name: "reports-wishlist-items-report___en"
  }, {
    path: "/services/layaways",
    component: _5d390b4f,
    name: "services-layaways___en"
  }, {
    path: "/services/repair-center",
    component: _9d24a462,
    name: "services-repair-center___en"
  }, {
    path: "/fr/cms/components",
    component: _5f276967,
    name: "cms-components___fr"
  }, {
    path: "/fr/cms/contents",
    component: _8de440ba,
    name: "cms-contents___fr"
  }, {
    path: "/fr/configuration/admin-settings",
    component: _4916166f,
    name: "configuration-admin-settings___fr"
  }, {
    path: "/fr/configuration/integrations",
    component: _193b261e,
    name: "configuration-integrations___fr"
  }, {
    path: "/fr/customer-credits/create-new",
    component: _fef6d664,
    name: "customer-credits-create-new___fr"
  }, {
    path: "/fr/register/transactions",
    component: _5ddc8014,
    name: "register-transactions___fr"
  }, {
    path: "/fr/reports/customer-balances",
    component: _7840b5db,
    name: "reports-customer-balances___fr"
  }, {
    path: "/fr/reports/customer-statements-dialog",
    component: _c717b786,
    name: "reports-customer-statements-dialog___fr"
  }, {
    path: "/fr/reports/events-report",
    component: _504d119d,
    name: "reports-events-report___fr"
  }, {
    path: "/fr/reports/layaways-report",
    component: _184b6b56,
    name: "reports-layaways-report___fr"
  }, {
    path: "/fr/reports/sales-reports",
    component: _06c4e833,
    name: "reports-sales-reports___fr"
  }, {
    path: "/fr/reports/wishlist-items-report",
    component: _70c79ac4,
    name: "reports-wishlist-items-report___fr"
  }, {
    path: "/fr/services/layaways",
    component: _5d390b4f,
    name: "services-layaways___fr"
  }, {
    path: "/fr/services/repair-center",
    component: _9d24a462,
    name: "services-repair-center___fr"
  }, {
    path: "/services/layaways/create-new-layaway",
    component: _66c213e7,
    name: "services-layaways-create-new-layaway___en"
  }, {
    path: "/services/repair-center/create-new-repair-ticket",
    component: _04e5253e,
    name: "services-repair-center-create-new-repair-ticket___en"
  }, {
    path: "/fr/services/layaways/create-new-layaway",
    component: _66c213e7,
    name: "services-layaways-create-new-layaway___fr"
  }, {
    path: "/fr/services/repair-center/create-new-repair-ticket",
    component: _04e5253e,
    name: "services-repair-center-create-new-repair-ticket___fr"
  }, {
    path: "/fr/cms/components/:componentId",
    component: _05d1f9b4,
    name: "cms-components-componentId___fr"
  }, {
    path: "/fr/cms/contents/:contentId",
    component: _6fbb4c74,
    name: "cms-contents-contentId___fr"
  }, {
    path: "/cms/components/:componentId",
    component: _05d1f9b4,
    name: "cms-components-componentId___en"
  }, {
    path: "/cms/contents/:contentId",
    component: _6fbb4c74,
    name: "cms-contents-contentId___en"
  }, {
    path: "/fr/catalog/:id/products",
    component: _3a7e3ea9,
    name: "catalog-id-products___fr"
  }, {
    path: "/fr/product/:id?/:slug",
    component: _0c29f6c7,
    name: "product-id-slug___fr"
  }, {
    path: "/catalog/:id/products",
    component: _3a7e3ea9,
    name: "catalog-id-products___en"
  }, {
    path: "/product/:id?/:slug",
    component: _0c29f6c7,
    name: "product-id-slug___en"
  }, {
    path: "/",
    component: _e665b6a4,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
