
const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {

}

export const actions = {

  async printReceiptReport({}, invoiceID) {
    try{
      if(invoiceID.invoiceID && invoiceID.invoiceID > 0){
        const response = await this.$axios.get(`webpos/reports/print-receipt/${invoiceID.invoiceID}`, {responseType: 'blob'})
        if(response){
          const createdFile = new File(
            [response.data],
            "report.pdf" || 'report.pdf',
            {type: 'application/pdf'}
          )
          const url = window.URL.createObjectURL(createdFile)
          window.open(url, '_blank')
        }
        else{
          this.$toast.error('Error while printing report')
        }
      }
    }
    catch (ex) {
      if (ex.response && ex.response.status == 404) {
        this.$toast.show('The invoice that you are trying to print cannot be found')
        return;
      }
      this.$toast.error('Error while printing report')
    }

  },

  async printInvoiceReport({}, invoiceID) {
    try{
      if(invoiceID.invoiceID && invoiceID.invoiceID > 0) {
        const response = await this.$axios.get(`webpos/reports/print-invoice/${invoiceID.invoiceID}`, {responseType: 'blob'})
        if (response) {
          const createdFile = new File(
            [response.data],
            "report.pdf" || 'report.pdf',
            {type: 'application/pdf'}
          )
          const url = window.URL.createObjectURL(createdFile)
          window.open(url, '_blank')
        }
        else{
          this.$toast.error('Error while printing report')
        }
      }
      else {
        this.$toast.show('The invoice that you are trying to print cannot be found')
      }
    }
    catch (ex) {
      if (ex.response && ex.response.status == 404) {
        this.$toast.show('The invoice that you are trying to print cannot be found')
        return;
      }
      this.$toast.error('Error while printing report')
    }

  },

  async printCustomerCreditReport({}, customerCreditID) {
    if(customerCreditID && customerCreditID > 0){
      this.$axios
        .get(`webpos/reports/print-customer-credit/${customerCreditID}`, {responseType: 'blob'})
        .then((response) => {
          /*  const contentDisposition = response.headers['content-disposition']
           const match = contentDisposition.match(/filename\s*=\s*(\w+-\w+)+/i)
           const [fullFileName, fileName] = match */
          const createdFile = new File(
            [response.data],
            "report.pdf" || 'report.pdf',
            {type: 'application/pdf'}
          )
          const url = window.URL.createObjectURL(createdFile)
          window.open(url, '_blank')
        })
    }

  },

  async printDailyReport({}, date) {
    try{
      const response = await this.$axios.get(`webpos/reports/print-daily-report/${date}`, {responseType: 'blob'})
      if(response){
        const createdFile = new File(
          [response.data],
          "report.pdf" || 'report.pdf',
          {type: 'application/pdf'}
        )
        const url = window.URL.createObjectURL(createdFile)
        window.open(url, '_blank')
      }
      else{
        this.$toast.error('Error while printing report')
      }

    }
    catch{
      this.$toast.error('Error while printing report')
    }
  },

  async printStatementReport({}, orderID) {
    console.log(orderID)
    try{
      if(orderID.orderID && orderID.orderID > 0) {
        const response = await this.$axios.get(`webpos/reports/print-layaway-statement/${orderID.orderID}`, {responseType: 'blob'})
        if (response) {
          const createdFile = new File(
            [response.data],
            "report.pdf" || 'report.pdf',
            {type: 'application/pdf'}
          )
          const url = window.URL.createObjectURL(createdFile)
          window.open(url, '_blank')
        }
        else{
          this.$toast.error('Error while printing report')
        }
      }
    }
    catch{
      this.$toast.error('Error while printing report')
    }

  },
}
