import {mapFilters} from "@/utils/helpers";

const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {

  async fetchOrders({commit, state}, {pagingInfo, filters}){
    const url = `/webpos/sales-order/`;

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    const result = await this.$axios.$post(url, filter);

    if(result && !result.hasErrors){
      return result.items;
    }
    else{
      this.$toast.error('Error while getting orders.')
      return [];
    }
  },

  async createWebPOSOrder({commit}, {orderData}) {
    return await this.$axios.$post(`/webpos/sales-order/create-sales-order`, orderData)
  },

  async getSalesOrderDetailsByID({commit}, {orderID}) {
    const url = `/webpos/sales-order/detail`;
    try{
       const result = await this.$axios.$get(`${url}/${orderID}`);

       if(result && !result.hasErrors){
         return result.value;
       }
       else{
         this.$toast.error('Error while getting order details.')
         return [];
       }
    }
    catch{
     this.$toast.error('Error while getting order details.')
    }
  }

}
