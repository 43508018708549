import {formActions} from "@/enums/formActions";
import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  creditCards: [],
  creditMemos: [],
  payments: {
    items: [],
    itemCount: null
  },
})

export const state = initState

export const mutations = {
  setCreditCards(state,{creditCards}){
    state.creditCards = creditCards
  },
  setPayments(state, {payments}) {
    state.payments = payments
  },
  setCustomerCredits(state, {customerCredits}) {
    state.customerCredits = customerCredits
  },
}

export const actions = {
  async postPayment({commit}, {paymentData}) {
    const paymentResult = await this.$axios.$post(`webpos/payments/save-payment`, paymentData)
    return paymentResult
  },
  fetchCreditCards({commit}, customerID) {
      return this.$axios.$get(`/webpos/credit-card/${customerID}`).then((creditCards) => {
        commit('setCreditCards', {creditCards})
        return creditCards
      })
  },
  async getPayments({commit, dispatch}, {filters, pagingInfo}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/webpos/payments/get-payments`
          let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);

          let response = await this.$axios.$post(url,filter)

          let payments = await dispatch('mapPayments', {response: response})
          commit('setPayments', {payments: payments})
          resolve(payments)
        },
        formActions.Load,
        'payments'
      )
    })
  },
    mapPayments({commit},{response}){
      let payments = {
        items: [],
        itemCount: null
      }
      payments.items = response.items
      payments.itemCount = response.pagingData.itemCount
      return payments
  },
  fetchCustomerCredits({commit}, customerID) {
    const customerCredits = this.$axios.$get(`/webpos/customer-credit/${customerID}`)
    commit('setCustomerCredits', {customerCredits})

    return customerCredits
  },
}
