import {formActions} from "@/enums/formActions";

const initState = () => ({
  applicationCurrencies: [],
})

export const state = initState

export const getters = {
}

export const mutations = {
  setApplicationCurrencies(state, {applicationCurrencies}) {
    state.applicationCurrencies = applicationCurrencies
  },
}

export const actions = {
  async fetchApplicationCurrencies(){
    const url = `/webpos/settings`
    let response = await this.$axios.$get(url);
    if(response && !response.hasErrors){
      return response.value;
    }
    else {
      this.$toast.error(response.errorMessages[0].value);
    }
  }
}
