
const initState = () => ({
  wishlistItems: []
})

export const state = initState

export const getters = {

}

export const mutations = {

  setWishlist(state, {wishlist}){
    state.wishlistItems = wishlist
  },
  resetWishlist(state){
    state.wishlistItems = []
  },
  loadMore(state, {wishlist}){
    wishlist.forEach(x => state.wishlistItems.push(x))
  }
}

export const actions = {
  async getCustomerWishlistByID({commit}, filter){
    try{
      const result =  await this.$axios.$get(`/webpos/wishlist/${filter.customerId}?pageSize=${filter.pageSize}&pageNo=${filter.pageNo}`)
      if(result){
        if (result.errorMessages){
          this.$toast.error(result.errorMessages["error"])
          return
        }
        if (!result.hasErrors) {
          if (filter.pageSize > 0){
            commit('loadMore', {wishlist: result.value})
          }else {
            commit('setWishlist', {wishlist: result.value})
          }
        } else {
          this.$toast.error('Error while selecting the customer');
        }
      }
    }
    catch (e){
      this.$toast.error('Error while selecting the customer');
    }
  },
  async deleteWishlistItem({commit}, itemID){
    let success;
    try{
      const res = await this.$axios.$post(`/webpos/wishlist/delete/${itemID}`)
      if(res){
        if (res.result === 0){
          this.$toast.success('Item successfully deleted from wishlist!');
          success =  true;
        }
        else{
          this.$toast.error('Error while deleting wishlist item');
          success = false;
        }
      }
    }
    catch(e){
      this.$toast.error('Error while deleting wishlist item');
      success = false;
    }
    return success;
  },
  async createWishlist({commit}, wishlist){
    let success;
    try{
      const res = await this.$axios.post(`webpos/wishlist/add-to-wishlist`,wishlist)
        if(res && !res.data.hasErrors){
            this.$toast.success('Item successfully added to wishlist!');
            success = true;
        }
        else{
            this.$toast.error('Error while inserting wishlist item')
            success = false;

        }
    }
    catch(e){
      this.$toast.error('Error while adding wishlist item');
      success = false;
    }
    return success;
  }
}
