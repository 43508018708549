// import createPersistedState from 'vuex-persistedstate'

const initState = () => ({
  loading: false,
  frontendId: 2
})

export const state = initState

export const getters = {
  loading: (state) => state.loading,
}

export const mutations = {
  setLoading(state, {loading}) {
    state.loading = loading
  },
}
export const actions = {
  async nuxtServerInit({dispatch, state}) {
    // await dispatch('common/companyDetails/fetchCompanyDetails')
    await dispatch('common/formatter/fetchFormatSettings')
    // await dispatch('common/hardcodedValue/fetchHardcodedValues')
    if (state.auth.loggedIn) {
    //   await dispatch('common/entityStatus/fetchClosedQuoteStatuses')
    //   await dispatch('common/entityStatus/fetchOpenQuoteStatuses')
    //   await dispatch('common/itemType/fetchItemTypes')
         await dispatch('settings/fetchFields')
    //   await dispatch('common/itemType/fetchMetals')
    //   await dispatch('common/entityStatus/fetchSalesOrderStatuses')
    //   await dispatch('common/entityStatus/fetchJobOrderStatuses')
    //   await dispatch('backoffice/settings/fetchAdminSettings')
    //   await dispatch('common/crm/territorialUnit/fetchCountries')
    //   await dispatch('common/crm/territorialUnit/fetchAllStates')
    //   await dispatch('backoffice/payments/creditCard/fetchCreditCards')
    //   await dispatch('backoffice/payments/creditCard/fetchCreditCardTypes')
    //   await dispatch('common/crm/contactPerson/getCustomerContactPersons')
    //   await dispatch('common/crm/customerType/fetchCustomerTypes')
    //   await dispatch('common/userProfile/getUserDetails')
    }
  },
}

// export const plugins = [createPersistedState()]
