import {formActions} from '@/enums/formActions'

const initState = () => ({
  itemGroups: [],
})

export const state = initState

export const getters = {
  getItemGroups: (state) => state.itemGroups,
}

export const mutations = {
  setItemGroups(state, {itemGroups}) {
    state.itemGroups = itemGroups
  },
}

export const actions = {
  async fetchItemGroups({commit}) {
    return await this.$axios.$get('webpos/itemgroup/').then((result) => {
      return result.map((element) => {
        return {text: element.n, value: element.id}
      })
    })
  },

  async fetchItemTypesByAdminSettingCode({commit}, adminSettingCode) {
      try {
        return await this.$axios.$get(`webpos/itemgroup/get-item-types/${adminSettingCode}`);
      } catch(error){
        console.error('Error fetching item types by item groups,', error);
        this.$toast.error('Error fetching item types by item groups');
      }
  }
}
