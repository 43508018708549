import {jobTypeCodes} from '@/enums/jobTypeCodes'
import {jobSubtypeCodes} from "@/enums/jobSubtypeCodes";
import {dashboardElements} from "@/enums/dashboardElements";
import {filterOptions} from "@/enums/filterOptions";
import {salesStatsGroupBy} from "@/enums/salesStatsGroupBy";

const initState = () => ({
  dashboard: [
    { type: dashboardElements.SALES_ORDERS, data: [], summaries: []},
    { type: dashboardElements.TOTAL_SALES, data: [['Date','Amount']]},
    { type: dashboardElements.SALES_AMOUNT, data: [ ['Date', 'Amount']]},
    { type: dashboardElements.DAILY_SALES, data: [['Date', 'Amount']]},
    { type: dashboardElements.MONTHLY_SALES, data: [['Date', 'Amount']]},
    { type: dashboardElements.LAYAWAYS, data: [], summaries: []}
  ],
})

export const state = initState

export const mutations = {
  setSalesOrderSummaries(state, {salesOrderSummaries}) {
    state.dashboard.find(d=> d.type === dashboardElements.SALES_ORDERS).summaries = salesOrderSummaries
  },
  setLayawayOrderSummaries(state, {layawayOrderSummaries}) {
    state.dashboard.find(d=> d.type === dashboardElements.LAYAWAYS).summaries = layawayOrderSummaries
  },
  setSalesStats(state,{sales, dashboardElement, groupBy, filterOption,selectedDate}){
    if (sales) {
      state.dashboard.find(d => d.type === dashboardElement).data = [['Date','Amount']]

      let date = new Date();
      let currDay
      let lastDay
      let amount = 0

      if (filterOption === filterOptions.TODAY || filterOption === filterOptions.DAY){

        let startTime = new Date(date.getFullYear(), date.getMonth(),date.getDate(), 0)
        let endTime = new Date(date.getFullYear(), date.getMonth(),date.getDate(), 23)

        for (let i = 0; startTime<=endTime;i++){
          let hourFormatted = startTime.toLocaleString("en-EN",{hour: "numeric"})

          if (dashboardElement === dashboardElements.TOTAL_SALES){
            amount = sales.filter((s) => new Date(s.date).getHours() === startTime.getHours())[0]?.itemCount
          }else{
            amount = sales.filter((s) => new Date(s.date).getHours() === startTime.getHours())[0]?.price
          }

          state.dashboard
            .find(d => d.type === dashboardElement)
            .data
            .push([ hourFormatted
              ,amount ? amount : 0])
          startTime.setHours(startTime.getHours() + 1);

        }

        return
      }

      if (filterOption === filterOptions.MONTH) {
        date = selectedDate ? new Date(selectedDate) : date
        currDay = new Date(date.getFullYear(), date.getMonth(), 1)
        lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      }
      if (filterOption === filterOptions.WEEK){
        currDay = new Date(date.setDate(date.getDate() - date.getDay()));
        lastDay = new Date(date.setDate(date.getDate() - date.getDay() + 6));

      }

        for (let i = 0; currDay<=lastDay;i++){
          let formatted = currDay.toLocaleDateString("en-EN", {day: "numeric", month: "short"})

          if (dashboardElement === dashboardElements.TOTAL_SALES){
            amount = sales.filter((s) => new Date(s.date).toLocaleDateString("en-EN", {day: "numeric", month: "short"}) === formatted)[0]?.itemCount
          }else{
            amount = sales.filter((s) => new Date(s.date).toLocaleDateString("en-EN", {day: "numeric", month: "short"}) === formatted)[0]?.price
          }

          state.dashboard
            .find(d => d.type === dashboardElement)
            .data
            .push([ formatted
              ,amount ? amount : 0])
          currDay.setDate(currDay.getDate()+1)
        }

    }
  }
}

export const actions = {
  async getSalesOrders({commit}, {filters}) {
    let url = `/webpos/dashboard/orders/?subTypeCode=${jobSubtypeCodes.POSSalesOrder}`
    if(filters && filters.fromDate && filters.dateTo){
      url =`${url}&fromDate=${filters.fromDate}&dateTo=${filters.dateTo}`
    }
    const result = await this.$axios.$get(url)
    if(result && !result.hasErrors){
      return result.value
    }
    else{
      this.$toast.error("Error while getting sales orders!");
      return []
    }
  },
  async getLayawayOrders({commit}, {filters}) {
    let url = `/webpos/dashboard/orders/?subTypeCode=${jobSubtypeCodes.LayawayOrder}`
    if(filters && filters.fromDate && filters.dateTo){
      url =`${url}&fromDate=${filters.fromDate}&dateTo=${filters.dateTo}`
    }
    const result = await this.$axios.$get(url)
    if(result && !result.hasErrors){
      return result.value
    }
    else{
      this.$toast.error("Error while getting sales orders!");
      return []
    }
  },
  async getOrderStatusSummaries({commit}, {dashboardElement}) {
    const orderSubType = dashboardElement === dashboardElements.SALES_ORDERS ? jobSubtypeCodes.POSSalesOrder : jobSubtypeCodes.LayawayOrder
    const orderSummaries = await this.$axios.$get(
      '/webpos/dashboard/orders-status-summary/' + orderSubType
    )
    if(orderSummaries && !orderSummaries.hasErrors){
      dashboardElement === dashboardElements.SALES_ORDERS
        ? commit('setSalesOrderSummaries', {salesOrderSummaries: orderSummaries.value})
        : commit('setLayawayOrderSummaries', {layawayOrderSummaries: orderSummaries.value})
    }
    else{
      this.$toast.error("Error while getting order status summaries!");
    }

  },

  async getSalesStats({commit},{filters, dashboardElement,selectedDate}){
    const salesStats = await this.$axios.get(
      '/webpos/dashboard/sales/?entityTypeCode=' + jobTypeCodes.SalesOrder +
      "&fromDate=" + filters.fromDate +
      '&dateTo=' + filters.dateTo +
      '&groupBy=' + filters.groupBy
    )
    commit('setSalesStats', {sales: salesStats.data, dashboardElement: dashboardElement, groupBy: filters.groupBy, filterOption: filters.filterOption, selectedDate: selectedDate})
  }
}
